import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { APPTYPES, automaticComponents, manualcomponents } from '../../constants/common-constants';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-custom-regex',
  templateUrl: './custom-regex.component.html',
  styleUrl: './custom-regex.component.scss'
})
export class CustomRegexComponent implements OnInit {
  @Output() closePanel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
  @Input() documentType: any;
  @Input() isContractFormat: any;
  @Input() template: any;
  @Input() verifyLoader: boolean = false;
  dynamicForm     !: FormGroup;
  customLrFormat: any[];
  sampleId: string = '';
  regexFormat: string = '';
  faTrash = faTrash;
  faPlus  = faPlus
  formatTypes = ['Manual', 'Automatic']
  formatType = new FormControl();
  formatTypeCmls = this.formBuilder.control(this.formatTypes[1]);
  components: any;
  SequenceItem: any = [];
  envType = environment?.envType || 'SDEX';
  appType = APPTYPES;
  templateFormat: any[];

  @Input() errorMsg: any

  onSelectType(control: any, event: any) {
    if (!(event?.target?.value)) {
      return;
    }

    let selectedValue = this.components[event.target.value];
    control.formatType = selectedValue?.formatType;
    control.regex = selectedValue?.regex;
    control.stringLength = selectedValue?.len;
    control.value = selectedValue?.value;
    control.startAt = selectedValue?.startAt;
    this.formatDocId();
  }
  constructor(private formBuilder: FormBuilder,
    public commonService         : CommonService,
  ) {
  }

  initializeForm() {
    this.dynamicForm = this.formBuilder.group({
      lrFormat: this.formBuilder.array([this.createFormArrayGroup()]),
    });
  }

  createFormArrayGroup() {
    return this.formBuilder.group({
      type: ['', Validators.required],
      stringLength: ['', Validators.required],
      regex: ['']
    });
  }

  ngOnInit() {
    if (this.documentType?._id) {
      this.formatType.patchValue(this.documentType?.formatDetails?.formatType)
    }
    if (this.envType == this.appType.dams) {
      if (this.documentType?.formatDetails?.formatType == this.formatTypes[0]) {
        this.components = manualcomponents;
      } else {
        this.components = automaticComponents;
      }
    }
    if (this.envType == this.appType.clms) {
      this.formatType.patchValue(this.formatTypes[1])
      this.components = automaticComponents;
    }
    this.initializeForm();
    if (this.envType === this.appType.clms) {
      this.setTemplateFormat();
    }
    if (this.envType === this.appType.dams) {
      this.customLrFormat = JSON.parse(JSON.stringify(this.documentType?.formatDetails?.numberFormat?.length ? this.documentType.formatDetails.numberFormat : [{}])) ;
      this.regexFormat = this.documentType?.formatDetails?.allowedRegex;
      this.sampleId = this.documentType?.formatDetails?.sampleFormat;
    }

  }

  setTemplateFormat() {
    const templateFormat: any = [];
    this.template?.templateFormat?.forEach((x: any) => {
      templateFormat.push({
        formatType: x.tempType,
        startAt: x.startAt,
        len: x.stringLength,
        nextIncrementValue: x.nextIncrementValue,
        value: x.value
      })
    })
    this.customLrFormat = templateFormat?.length ? templateFormat : [{}];
    this.sampleId =  this.template?.sampleFormat;
  }
  onCloseIconClick(value: boolean) {
    this.closePanel.emit(value);
  }

  addFormArrayGroup() {
    this.customLrFormat.push({ type: '' });
  }

  isValidFormat() {
    let isValid = true;
    for (const customLrFormat of this.customLrFormat) {
      if ((!customLrFormat.formatType) || (customLrFormat.formatType === 'Static' && !customLrFormat.value)) {
        isValid = false;
      }
    }
    return !isValid;
  }

  isValidAutoFormat() {
    if (this.formatType.value === this.formatTypes[1]) {
      for (const customLrFormat of this.customLrFormat) {
        if (customLrFormat?.formatType == 'Sequence' && (!customLrFormat?.len || !customLrFormat?.startAt)) {
          return true;
        }
        if (customLrFormat?.formatType == 'RS' && !customLrFormat?.len) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  deleteFormat(index: number) {
    this.customLrFormat.splice(index, 1);
    this.formatDocId();
  }

  formatDocId() {
    this.sampleId = '';
    this.regexFormat = '^';
    for (let format of this.customLrFormat) {
      if (format.formatType === 'Static') {
        this.regexFormat += format.value || '';
        this.sampleId += format.value || '';
      } else if (format.formatType === 'RS') {
        this.regexFormat += format.regex + `{1,${format.len || 30}}`;
        this.sampleId += 'X'.repeat(format.len || 5);
      }
      else if (format.formatType === 'Numeric') {
        this.regexFormat += format.regex + `{1,${format.len || 30}}`;
        this.sampleId += '1'.repeat(format.len || 5);
      } else {
        this.regexFormat += format.regex;
        this.sampleId += format.formatType;
      }
      if (format.separator) {
        this.regexFormat += format.separator;
        this.sampleId += format.separator;
      }
    }
    this.regexFormat += "$";
  }

  saveFormat() {
    if (this.envType == this.appType.dams) {
      this.onSave.emit({
        customLrFormat: this.customLrFormat,
        sampleId: this.sampleId,
        regexFormat: this.regexFormat,
        formatType: this.formatType.value
      })
    }

    if (this.envType === this.appType.clms) {
      this.verifyLoader = true;
      this.templateFormat = []
      this.customLrFormat.forEach((x: any) => {
        this.templateFormat.push({
          tempType: x.formatType,
          startAt: x.startAt,
          stringLength: x.len,
          nextIncrementValue: x.nextIncrementValue,
          value: x.value
        })
      })
      this.onSave.emit({sampleFormat:this.sampleId,templateFormat:this.templateFormat})
    }
  }

  onSelectFormatType(event: any) {
    this.customLrFormat = [{}];
    this.sampleId = '';
    if (event.target.value == this.formatTypes[0]) {
      this.components = manualcomponents;
    } else {
      this.components = automaticComponents;
    }
    if (event.target.value == this.documentType?.formatDetails?.formatType) {
      this.createForm();
    }

  }

  createForm() {
    this.initializeForm();
    this.customLrFormat = this.documentType?.formatDetails?.numberFormat?.length ? this.documentType.formatDetails.numberFormat : [{}];
    this.regexFormat = this.documentType?.formatDetails?.allowedRegex;
    this.sampleId = this.documentType?.formatDetails?.sampleFormat;
  }
}
