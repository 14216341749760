import { Component, Inject } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from './home/auth/auth.service';
import { environment } from 'src/environments/environment';
import { APPTYPES } from './share/constants/common-constants';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',

})
export class AppComponent {
  customUrldata: any;
  envType = environment?.envType || 'SDEX';
  appType = APPTYPES;
  constructor(private router: Router,   private authService: AuthService,    @Inject(DOCUMENT) private document: Document) {
    let definedRouter = this.router;
    this.router.events.subscribe(function (event) {

    });

    function LogoutApplication(){
      localStorage.clear();
      definedRouter.navigate(['login']);
    }

    const bc = new BroadcastChannel("sdex-web-app");
    bc.onmessage = (event) => {
      if(event.data === 'logOut'){
        LogoutApplication();
      }else if (event.data !== window.sessionStorage["tabId"]) {
         localStorage.setItem("loadingNewWebsite", (new Date()).getTime().toString());
      }
    };

     window.addEventListener("load", function (e)
    {
      if (!window.sessionStorage["tabId"] && router.url !== "/setup-account" && router.url !== "/forgot-password"){
        window.sessionStorage["tabId"] = Date.now();
        bc.postMessage(window.sessionStorage["tabId"].toString());
        setTimeout(function (){
          let modifiedTime = localStorage.getItem("loadingNewWebsite");
          if((!modifiedTime) || (Math.floor((((new Date()).getTime()) - Number(modifiedTime)) / 1000)) > 2 ){
            bc.postMessage("logOut");
            LogoutApplication();
          }
        }, 2000);
      }
      return null;
    });

    //getting custom url data for fav icon
    // this.authService.getOrgLoginData()?.subscribe({
    //   next: (response: any) => {
    //     this.customUrldata = response.data;
    //     if(this.envType == 'DAMS'){
    //     this.changeFavicon(this.customUrldata?.favIcon)
    //     }
    //   },error:(err)=>{

    //   }
    // })
  }

  changeFavicon(newIconPath: string) {
      const favicon = this.document.getElementById('favicon') as HTMLLinkElement;
      if (favicon) {
          favicon.href = newIconPath;
      }
  }
  }

