import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserSettingService {
  constructor(private http: HttpClient) {}

  searchUserInSystem(searchText: any): Observable<any> {
    let url = environment.API_URL + '/v1/user/parent-users-in-system';
    return this.http.post(url, searchText);
  }
  updateProfile(payload: any): Observable<any> {
    let url = environment.API_URL + '/v1/user/self';
    return this.http.put(url, payload);
  }

  uploadFile(payload: any): Observable<any> {
    let url = environment.API_URL + '/v1/digilocker/public-file/upload';
    return this.http.post(url, payload);
  }

  private isProfilePicUpdated: Subject<any> = new Subject();
  private updatedUserName : Subject<string> = new Subject();

  public getUpdatedUserName() : Observable<string> {
    return this.updatedUserName.asObservable();
  }

  public setUpdatedUserName(name : string) {
    this.updatedUserName.next(name);
  }

  public getProfilePic(): Observable<any> {
    return this.isProfilePicUpdated.asObservable();
  }

  public setProfilePic(uploadedProfilePic: any) {
    this.isProfilePicUpdated.next(uploadedProfilePic);
  }

  updateCompanydetails(payload: any, companyId: string): Observable<any> {
    let url =
      environment.API_URL + '/v1/company/' + companyId + '/update-details';
    return this.http.post(url, payload);
  }

  userSubCompanyDisable(userId: any): Observable<any> {
    let url = environment.API_URL + '/v1/user/sub-company-user/'+userId;
    return this.http.delete(url);
  }

  userDisable(userId: any): Observable<any> {
    let url = environment.API_URL + '/v1/user/delete/'+userId;
    return this.http.delete(url);
  }

   getblockchainkey(userId:any):Observable<any>{
    let url = environment.API_URL +'/v1/blockchain-key/retrieve/'+userId;
    return this.http.get(url);
   }

   getqrcode(payload: any): Observable<any> {
    let url = environment.API_URL +'/v1/oauth/enable-mfa';
    return this.http.post(url,payload);
   }

   ismfaEnabled(): Observable<any> {
    let url = environment.API_URL +'/v1/oauth/is-mfa-enabled';
    return this.http.get(url);
   }

   disableMfa(payload: any): Observable<any> {
    let url = environment.API_URL +'/v1/oauth/disable-mfa';
    return this.http.post(url,payload);
   }

   enableMfa(): Observable<any> {
    let url = environment.API_URL +'/v1/oauth/enable-mfa';
    return this.http.post(url,null);
   }

   verifyTotp(userId: any): Observable<any> {
    let url = environment.API_URL +'/v1/oauth/verify-totp'+userId;
    return this.http.post(url, userId)
   }

   ismfaEnabledByUser(id: any): Observable<any> {
    let url = environment.API_URL +'/v1/oauth/'+id+'/is-mfa-enabled';
    return this.http.get(url);
   }

   mfaDisabledByUser(id: any): Observable<any> {
    let url = environment.API_URL +'/v1/oauth/'+id+'/disable-mfa';
    return this.http.post(url,null);
   }

   getModulesList(id : any, params: any): Observable<any>{
    let url = environment.API_URL + '/v1/company-preference/preferences-list/'+id+`?restrictTo_nin=${environment.envType}`;
    return this.http.get(url, {params});
   }
  
   getpreferencelist(rootCompany: any, params: any): Observable<any>{
    let url = environment.API_URL + '/v1/company-preference/company-preferences-list/'+rootCompany;
    return this.http.get(url, {params});
   }
  

   disablePrefrences(payload : any): Observable<any>{
    let url = environment.API_URL + '/v1/company-preference/'+payload._id;
    return this.http.post(url,payload);
   }


   getSharePointDetails(): Observable<any>{
    let url = `${environment.API_URL }/v1/sharepoint/get-delegated-user`;
    return this.http.get(url);
   }

   connectToSharepoint() :Observable<any>{
    let url = `${environment.API_URL }/v1/sharepoint/connect`;
    return this.http.get(url);
   }

}
