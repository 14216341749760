import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RegExpPatterns } from '../../enums/regex-pattern.enum';
import { APPTYPES } from '../../constants/common-constants';
import { environment } from 'src/environments/environment';
import {DocumentValutService} from 'src/app/share/document-valut/document-valut.service'

@Component({
  selector: 'app-sent-mail',
  templateUrl: './sent-mail.component.html',
  styleUrl: './sent-mail.component.scss'
})
export class SentMailComponent implements OnChanges {
  @Input() isSentMail      : boolean;
  @Input() verifyLoader    : boolean = false;
  @Input() selectedFile    : any;
  @Output() closeEvent     : EventEmitter<any> = new EventEmitter<any>
  @Output() onSubmit       : EventEmitter<any> = new EventEmitter<any>
  sentEmailFormGroup      !: FormGroup;
  errorMessage             : string;
  maxFilelength            : number = 25;
  emailList                : string[] = [];
  isFileTransfer           : boolean =false; 
  showUpdatedByDropDown    : boolean = false;
  emailSearchList          : any;
  emailsearch              : any = []
  selectedEmail            : any =[]
  showEmailIcon            : boolean = false;

  showDropDown                       : boolean = false;
  checkedList                        : any[];
  selectedIDs                        : any[];
  multiSelectedIDs                   : any[] = [];
  checkedMultiList                   : any = [];
  multiListData                      : any = [];
  listData                      : any = [];
  searchControl : FormControl

  envType                 = environment?.envType || 'SDEX';
  appType                 = APPTYPES;
  constructor(
    private _fb: FormBuilder,
    private documentValutService: DocumentValutService
    ) {
      this.searchControl = new FormControl('');
     }

  ngOnChanges(changes: SimpleChanges): void {
    this.createSentMailForm();
    this.getEmailSearch('');
  }
  createSentMailForm() {
    this.sentEmailFormGroup = this._fb.group({
      to: new FormControl(''),
      subject: new FormControl('', Validators.required),
      description: new FormControl(''),
    });
  }

  onCloseIconClick(){
    this.isSentMail = false;
    this.emailList = [];
    this.emailSearchList = [];
    this.selectedEmail = [];
    this.errorMessage = '';
    this.closeEvent.emit()  
    this.sentEmailFormGroup.reset(); 
  }

  triggerMail(){
    this.sentEmailFormGroup.controls['to'].patchValue(this.checkedMultiList);
    this.onSubmit.emit(this.sentEmailFormGroup.value);
  }
  
  largeFileName(fileName: string, type: any): string {
    if (fileName && fileName.length >= this.maxFilelength) {
      if (type == 'file') {
        return fileName.substring(0, this.maxFilelength) + '...';
      } else {
        return fileName;
      }
    } else if (fileName && fileName.length < this.maxFilelength && type == 'file') {
      return fileName;
    } else {
      return '';
    }
  }

  handleSelectedEmail(event: any) {
    this.sentEmailFormGroup.controls['to'].setValue(event);
    this.selectedEmail = event;
  }

  getEmailSearch(name: string) {
    this.emailSearchList = [];
    this.showEmailIcon = false;
    this.documentValutService.getEmailSearch(name).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          if(res['data'].length > 0){
            this.listData = res.data;
            this.emailSearchList = res.data
          }else{
            let regex = new RegExp(RegExpPatterns.EmailPattern) 
             if(regex.test(name)){
              this.showEmailIcon = true;
             }
          }
        }
      },
      error: (error: any) => {
        let regex = new RegExp(RegExpPatterns.EmailPattern)
        if (regex.test(name)) {
          this.showEmailIcon = true;
        }
     
      }
    });
  }

  selectEmail(status: boolean, value: any) {
    if (status) {
      this.checkedMultiList.push(value.emailAddress);
      this.multiselectedList(status, value.emailAddress);
    } else {
      let index = this.checkedMultiList.indexOf(value.emailAddress);
      this.checkedMultiList.splice(index, 1);
      this.multiselectedList(status, value.emailAddress);
    }
  }

  handleFilter(event: any) {
    this.getEmailSearch(event.target.value);
  }

  multiselectedList(status: boolean, emailAddress: any) {
    this.listData = this.listData?.map((x:any) => {
      if (status) {
        if (x.emailAddress === emailAddress) {
          return { ...x, checked: true };
        } else {
          return x;
        }
      } else  if (x.emailAddress === emailAddress) {
          return { ...x, checked: false };
        } else {
          return x;
        }
    });
  }

  showDropdown() {
     this.emailSearchList = this.listData;
     this.showDropDown = !this.showDropDown
  }

  addItem(newEmail: any) {
    if (!this.checkedMultiList.includes(newEmail)) {
      this.checkedMultiList.push(newEmail);
      this.emailSearchList?.push({ emailAddress: newEmail, checked: true })
      this.listData.push({ emailAddress: newEmail, checked: true })
      this.searchControl.patchValue('')
      this.showEmailIcon = false;
    }
  }

  closeItem() {
    this.checkedMultiList = [];
    this.listData = this.listData?.map((x: any) => {
      return { ...x, checked: false };
    });
  }

}
