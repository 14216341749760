import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { StorageKeys } from 'src/app/share/enums/storage.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient,
    private storageService: StorageService) { }

  login(payload: any,envType : string): Observable<any> {
    let url = `${environment.API_URL}/v1/oauth/${envType === 'CLMS' ? 'guest-token' : 'token'}`;
    return this.http.post(url, payload);
  }
  verifyTotp(payload: any): Observable<any> {
    let url = environment.API_URL + '/v1/oauth/verify-totp';
    return this.http.post(url, payload);
  }
  logout(): Observable<any> {
    let url = environment.API_URL + '/v1/oauth/logout';
    return this.http.get(url);
  }
  companySettings(payload: any, prefId: string): Observable<any> {
    let url = environment.API_URL + `/v1/company-preference/${prefId}`;
    return this.http.post(url, payload);
  }
  getcompanySettings(id: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-preference/${id}`
    return this.http.get(url)
  }

  getSelfDetails(): Observable<any> {
    let url = `${environment.API_URL}/v1/user/self?envType=${environment.envType}`;
    return this.http.get(url);
  }

  // organisation details start

  getOrgDetails(id: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-preference/${id}`
    return this.http.get(url)
  }

  isAdmin() {
    let userData: any = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    if (userData?.enrollmentType == 'Admin') {
      return true;
    }
    return false;
  }

  isRootUser() {
    let userData: any = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    let isPrimary: boolean = false;
    if (userData?.enrollmentType == 'Admin') {
      return false;
    }
    userData?.assignedTo?.map((ele: any) => {

      isPrimary = ele.primary;

    });
    if (isPrimary) {
      return true;
    }
    return false;
  }

  isCompanyAdmin() {
    let userData: any = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    let isAdmin: boolean = false;
    let isPrimary: boolean = false;
    userData?.assignedTo?.map((ele: any) => {
      isAdmin = ele.isAdmin;
    });
    if (isAdmin || userData?.isPrimary) {
      return true;
    }
    return false;
  }

  AddRoleAccess() {
    let userData: any = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    if (userData?.enrollmentType === 'Admin') {
      return true;
    } else if ((userData?.primary || userData?.isAdmin) && userData?.disabledPermissions.includes('company')) {
      return  true;
    }
    else {
      return this.rolePermission(userData);
    }
  }

  rolePermission(userData: any): boolean {
    let hasPermission = false;
    for (const presentRole of userData?.presentRoles || []) {
      for (const permission of presentRole.permissions || []) {
        if (permission.module != 'company') {
          if (permission.module === 'role') {
            hasPermission = true;
            break;
          }
        } else {
          hasPermission = false;
          break;
        }
      }
    }
    return hasPermission;
  }

  documentVaultAccess() {
    let hasPermission = false;
    let userData: any = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    if (userData.primary || userData.isAdmin) {
      return true;
    } else {
      for (const presentRole of userData?.presentRoles || []) {
        for (const permission of presentRole.permissions || []) {
          if (permission.module === 'document') {
            hasPermission = true;
            break;
          }
        }
      }
      return hasPermission;
    }
  }

  companyAccess() {
    let hasPermission = false;
    let userData: any = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    if (userData?.primary || userData?.isAdmin) {
      return true;
    } else {
      for (const presentRole of userData?.presentRoles || []) {
        for (const permission of presentRole.permissions || []) {
          if (permission.module === 'company') {
            hasPermission = true;
            break;
          }
        }
      }
      return hasPermission;
    }
  }

  getOrganizationStructureById(id: any) {
    let url = environment.API_URL + '/v1/company-structure';
    if (id) {
      url += '/' + id;
    }
    return this.http.get(url);
  }


  getOrgLoginData(): Observable<any> {
    let url = environment.API_URL + '/v1/company-preference/domain-details';
    return this.http.get(url);
  }

}
