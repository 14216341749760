import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage-service/storage.service';
import { StorageKeys } from '../../enums/storage.enum';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient,
    private storageService: StorageService) { }
    private scanFiles: Subject<any> = new Subject();
    private docFilesSearchCount: Subject<any> = new Subject();
    private clearDocsSearch: Subject<any> = new Subject();
    private reloadSidebar: Subject<any> = new Subject();

    // private scanFile = new BehaviorSubject<any>('');
    // getScanFile = this.scanFile.asObservable();

  // setScanFile(file: any) {
  //   this.scanFile.next(file);
  // }
  
  public getScanFiles(): Observable<any> {
    return this.scanFiles.asObservable();
  }

  public setScanFiles(uploadedProfilePic: any) {
    this.scanFiles.next(uploadedProfilePic);
  }
  
  public getFilesSearchCount(): Observable<any> {
    return this.docFilesSearchCount.asObservable();
  }

  public setFilesSearchCount(uploadedProfilePic: any) {
    this.docFilesSearchCount.next(uploadedProfilePic);
  }

  public getClearDocsSearch() : Observable<string> {
    return this.clearDocsSearch.asObservable();
  }

  public setClearDocsSearch(name : any) {
    this.clearDocsSearch.next(name);
  }

  public getReloadSidebar() : Observable<string> {
    return this.reloadSidebar.asObservable();
  }

  public setReloadSidebar(name : any) {
    this.reloadSidebar.next(name);
  }

  getPreferenceByModule(module: string): Observable<any> {
    let url = environment.API_URL + `/v1/company-preference/module/${module}`;
    return this.http.get(url);
  }

  getPreference(module: any, isItem: boolean, cb: any): any {
    this.getPreferenceByModule(module).subscribe({
      next: (res) => {
        cb(null, { module: module, subModules: res?.data });
      },
      error: (err) => {
        cb(null, null);
      },
    });
  }
  getModule(module: any,cb: any) {
    let obj = this.storageService.getDataFromLocalStorage('preference') || {};
    if(obj?.[module]){
      return cb(null, obj?.[module]);
    }
    this.getPreference(module, true, (err: any, prefs: any) => {
      if(prefs){
        obj[module] = prefs;
        this.storageService.setDataToLocalStorage('preference', obj);
      }
      cb(null, obj?.[module] || {});
    });
  }


  getGlobalPreferences(): Observable<any> {
    let url = environment.API_URL + `/v1/company-preference/global-preferences/`;
    return this.http.get(url);
  }

  getGlobalPreference(cb: any): any {
    this.getGlobalPreferences().subscribe({
      next: (res) => {
        cb(null, res?.data);
      },
      error: (err) => {
        cb(null, null);
      },
    });
  }

  globalPreferences(cb: any){
    let obj = this.storageService.getDataFromLocalStorage('preference') || {};
    if(obj?.fileExtensions){
      return cb(null, obj);
    }
    this.getGlobalPreference((err: any, prefs: any) => {
      if(prefs?.length){
        for(let pref of (prefs || [])){
          if(pref?.module){
            obj[pref?.module] = pref;
          }
        }
        this.storageService.setDataToLocalStorage('preference', obj);
      }
      cb(null, obj);
    });
  }

  orgLock() {
    let selfDetails: any = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    if (selfDetails?.assignedTo && selfDetails?.assignedTo[0]?.company?.organizationStatus === 'Locked') {
      return false;
    }
    return true;
  }
}
