<div class="offCanvas__container">
    <div class="offCanvas__contentWrapper">
        <div class="offCanvas__header">
            <div class="offCanvas__title">MFA</div>
            <img (click)="onCloseIconClick()" class="offCanvas__crossIcon"
                src="../../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon">
        </div>
        <div class="qr-details" *ngIf="mfaAutentication==='step1'">
            <div class="userManagementUsersList__user-management-section1">
                <p>Multi-factor authentication (MFA) devices
                </p>
                <!-- {{mfaEnabledDetails?.isMfaEnabled }}{{isMFA}} -->
                <app-common-button buttonClass="smallFilledButton" buttonLabel="Register Device"
                    *ngIf="isMfa"
                    (clickEvent)="movetoNext(mfaAutentication)"></app-common-button>
                <app-common-button [loadingIcon]="loadingIcon" buttonClass="smallFilledButton" buttonLabel="Disabled"
                    *ngIf="isMFA && mfaEnabledDetails?.isMfaEnabled"
                    (clickEvent)="disabledUserMfa()"></app-common-button>
            </div>
            <table class="custom-table-list" aria-label="users">
                <thead class="custom-table-list-headings">
                    <th class="custom-col-width-30">Device name</th>
                    <th class="custom-col-width-30">Created At</th>
                    <th class="custom-col-width-30">Disabled</th>
                </thead>
                <tbody>
                    <tr *ngIf="mfaEnabledDetails?.mfaDevice" class="custom-table-list-row-main cursor-pointer">
                        <td class="custom-col-width-30 userTable__status clickable-td">
                            {{ mfaEnabledDetails?.mfaDevice}}
                        </td>
                        <td class="custom-col-width-30 userTable__status clickable-td">
                            {{ mfaEnabledDetails?.enabledAt | date : "dd MMM yy, hh:mm a"}}
                        </td>
                        <td class="custom-col-width-30 userTable__status clickable-td">
                            <div *ngIf="!isMFA" class="fa-ban">
                                <app-common-icon [faIcon]="faBan"
                                    (clickEvent)="disableMfa(mfaEnabledDetails?.mfaDevice)"
                                    [loadingIcon]="loadingIcon"></app-common-icon>
                            </div>
                        </td>
                    </tr>
                    <tr class="custom-table-list-row-main cursor-pointer text-center"
                        *ngIf="!mfaEnabledDetails?.mfaDevice">
                        <small class="text-danger">No Device Registered!</small>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="qr-details" *ngIf="mfaAutentication==='step2'">
            <p class="userManagementUsersList__user-management-section1">Set Up Your authenticator app</p>
            <form [formGroup]="deviceForm">
                <div class="column">
                    <div class="col-md-6">
                        <div class="input-text-width">
                            <app-input-filed label="Device Name" placeholder="Device Name" [control]="$any(deviceForm).controls['deviceName']"
                              [starIcon]="'*'"></app-input-filed>
                            <div *ngIf="deviceForm.controls?.['deviceName']?.invalid && 
                                                        (deviceForm.controls?.['deviceName']?.dirty ||
                                                        deviceForm.controls?.['deviceName']?.touched)">
                                <div *ngIf="deviceForm.controls?.['deviceName']?.errors?.['required']">
                                    <small class="text-danger">Device Name is required.</small>
                                </div>
                            </div>
                          </div>
                    </div>
                </div>
            </form>
            <div class="steps__text">
                Install a compatible application such as Google Authenticator, Authenticator, Duo Mobile, or Authy app
                on your
                mobile device or computer
            </div>
            <div class="qr-img">
                <div class="col-md-6"><img class="scan-img" src="../../../assets/Images/mfa/mobile-qr.png" alt="">
                </div>
                <div class="col-md-6">
                    <div class="userDetails__subText">Authenticator app</div>
                    <div class="steps__description">
                        Authencate using a code generated by an app installed on your mobile device or computer
                    </div>
                </div>
            </div>
        </div>
        <div class="qr-details" *ngIf="mfaAutentication==='step3'">
            <p class="userManagementUsersList__user-management-section1">Set Up Your authenticator app</p>
            <div class="steps__text">
                Install a compatible application such as Google Authenticator, Authenticator, Duo Mobile, or Authy app
                on your
                mobile device or computer
            </div>
            <div class="qr-img">
                <div class="col-md-6">
                    <span>
                        <img [src]="qrCode" alt="qrCode">
                    </span>
                </div>
                <div class="col-md-6">
                    <div class="steps__description">
                        Open your authenticator app, chose Show QR Code on this page, then use the app to scan the
                        code,
                        Alternatively, you can type a secret key.
                    </div>
                </div>
            </div>
        </div>

        <div class="offCanvas__footer">
            <app-common-button *ngIf="mfaAutentication === 'step2'" buttonClass="smallbutton" buttonLabel="Cancel"
                (clickEvent)="onCloseIconClick()"></app-common-button>
            <app-common-button [loadingIcon]="loadingIcon" [disabled]="deviceForm.invalid"
                *ngIf="mfaAutentication === 'step2'" buttonClass="smallFilledButton" buttonLabel="Next"
                (clickEvent)="movetoNext(mfaAutentication)"></app-common-button>
            <app-common-button (clickEvent)="qrDone()" *ngIf="mfaAutentication === 'step3'"
                buttonClass="smallFilledButton" buttonLabel="Done"></app-common-button>
        </div>
        <small *ngIf="errorMessage" class="text-danger">{{errorMessage}}</small>
    </div>
</div>


<app-toast *ngIf="isToster" [title]="message" (closeToaster)="closeToster($event)"></app-toast>