export const environment = {
  production: false,
  rolesDisabled: false,
  envType: 'CLMS',//SDEX or DAMS ot CLMS
  API_URL: `https://api-gateway.dev.sdex.in/api`,
  IMAGE_URL: `https://axivise-images.s3.ap-south-1.amazonaws.com/app/`,
  PRIVATE_KEY : 'XGgsQthLzMQvTo2hIXltdVt3O1JiPgi8',
  Dynamsoft: {
    resourcesPath: 'assets/dwt-resources',
    dwtProductKey: 't01888AUAAIY/zEyw/nZF2vWOGN9FRIZbQfDVMAEhQRXBGIg6HoDIqsXE+6X/ocKhTGG3K22ZXvTosDH5SrHcrm+HDPVkQqTHPyc7OLW9U6W9Ex2cvOUUeS7DkHfb/ealCUzAawF0PQ4rgBrIteyA91BqgwXQAtQA1KoBFnC6iuPHp+wDUr+9sqGzkx2c2t5ZB6SNEx2cvOWMAfFB3BRXO+WAoD45G4AWoKcAyo/sEBDZA7QAPQAYvWcAvyHAKmo=',
    uploadTargetURL: ''
  }
};

