import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { UserSettingService } from 'src/app/share/services/User-Setting/user-setting.service';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrl: './mfa.component.scss'
})
export class MfaComponent implements OnInit{
  @Input() isMFA          : boolean;
  @Input() parentData     : any;
  qrCode                  : any;
  isPanelOpen             : boolean;
  errorMessage            : any;
  userDetailsData         : any;
  mfaEnabledDetails       : any;
  mfaAutentication        : string = "step1";
  message                 : string;
  deviceForm             !: FormGroup;
  loadingIcon             : boolean;
  isToster                : boolean;
  isMfa                   : boolean = false;
  @Output() closePanel    : EventEmitter<void> = new EventEmitter<void>();

  constructor(
   private userSettingService : UserSettingService,
   private fb        : FormBuilder,
  ) { }

  ngOnInit(): void {
    if (this.isMFA) {
      this.ismfaEnabledByUser();
    } else {
      this.isMfaEnabled();
    }
    this.createFormGroup();
  }

  createFormGroup() {
    this.deviceForm = this.fb.group({
      deviceName: ['', [Validators.required]],
    });
  }


  onCloseIconClick() {
    this.isPanelOpen = false;
    this.closePanel.emit();
  }

  getQrCode() {
    this.loadingIcon = true;
    const payload = {
      mfaDevice: this.deviceForm.value.deviceName
    }
    this.userSettingService.getqrcode(payload)?.subscribe({
      next: (res: any) => {
        if (res['success']) {
          this.loadingIcon = false;
          this.isToster = true;
          this.qrCode = res?.data;
          this.mfaAutentication = 'step3'
          this.message = 'Device added successfully';
        }
      },
      error: (error) => {
        this.loadingIcon = false;
        this.errorMessage = error?.error?.error?.message;
      }
    });
  }

  isMfaEnabled() {
    this.userSettingService.ismfaEnabled()?.subscribe({
      next: (res: any) => {
          this.mfaEnabledDetails = res?.data ||{};
          this.isMfa = !this.mfaEnabledDetails?.isMfaEnabled && !this.isMFA;
      },
      error: (error) => {
        this.errorMessage = error?.error?.error?.message;
      }
    });
  }
  ismfaEnabledByUser() {
    this.userSettingService.ismfaEnabledByUser(this.parentData?._id)?.subscribe({
      next: (res: any) => {
          this.mfaEnabledDetails = res?.data ||{};
          this.isMfa = !this.mfaEnabledDetails?.isMfaEnabled && !this.isMFA;
      },
      error: (error) => {
        this.errorMessage = error?.error?.error?.message;
      }
    });
  }

  disableMfa(name: any) {
    this.loadingIcon = true;
    const payload = {
      mfaDevice: name
    }
    this.userSettingService.disableMfa(payload).subscribe({
      next: (res: any) => {
        if (res['success']) {
          this.loadingIcon = false;
          this.isToster = true;
          this.isMfaEnabled();
          this.message = 'device disabled successfully';
        }
      },
      error: (error) => {
        this.loadingIcon = false;
        this.errorMessage = error?.error?.error?.message;
      }
    });
  }

  enableMfa() {
    this.userSettingService.enableMfa()?.subscribe({
      next: (res: any) => {
        console.log(res)
      },
      error: (error) => {
        this.errorMessage = error?.error?.error?.message;
      }
    });
  }

  disabledUserMfa() {
    this.loadingIcon = true;
    this.userSettingService.mfaDisabledByUser(this.parentData?._id).subscribe({
      next: (res: any) => {
        if (res['success']) {
          this.loadingIcon = false;
          this.isToster = true;
          this.ismfaEnabledByUser();
          this.message = 'Disabled successfully';
        }
      },
      error: (error) => {
        this.loadingIcon = false;
        this.errorMessage = error?.error?.error?.message;
      }
    });
  }

  movetoNext(value: any) {
    if (value == 'step1') {
      this.mfaAutentication = 'step2'
    }
    if (value == 'step2') {
      this.getQrCode();
    }
  }

  qrDone() {
    this.isMfaEnabled();
    this.onCloseIconClick();
  }

  closeToster(value: any){
    this.isToster = false;
  }

  protected faBan = faBan

}
