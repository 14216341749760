import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LookupService } from 'src/app/share/services/lookup/lookup.service';
import { RegExpPatterns } from 'src/app/share/enums/regex-pattern.enum';

@Component({
  selector: 'app-edit-contacts',
  templateUrl: './edit-contacts.component.html',
  styleUrl: './edit-contacts.component.scss'
})
export class EditContactsComponent {
  protected readonly faPlus = faPlus;
  protected readonly faTrash = faTrash;
  editFormGroup           !: FormGroup;
  verifyLoader: boolean = false;
  errorMessage: string;
  isEdit: boolean = false;
  selectedDetails: any;
  @Input() contactItem: any;
  @Output() submitedData: EventEmitter<any> = new EventEmitter();
  @Output() closePanel: EventEmitter<any> = new EventEmitter();
  permissionsList: any = [
    {
      _id: 'Co-Author',
      name: 'Co-Author'
    },
    {
      _id: 'E-Sign',
      name: 'E-Sign'
    }
  ]

  constructor(private _fb: FormBuilder,
    private lookupService: LookupService
  ) { }

  ngOnInit(): void {
    this.createFormGroup();
    if (this.contactItem) {
      this.isEdit = true;
      this.selectedDetails = this.contactItem;
      setTimeout(() => {
        this.patchContact();
      })
    }
  }

  createFormGroup() {
    if (!this.editFormGroup) {
      this.editFormGroup = this._fb.group({
        name: ['', [Validators.required]],
        shortName: ['', [Validators.required]],
        address: this._fb.group({
          line1: [''],
          line2: [''],
          city: [''],
          state: [''],
          country: [''],
          pincode: [''],
        }),
        userDetails: this._fb.array([]),
      })
    }
  }

  onCloseIconClick(value: any) {
    this.closePanel.emit(value);
    this.editFormGroup.reset();
    this.partyForms.clear();
  }

  patchContact() {
    if (this.selectedDetails) {
      this.editFormGroup.patchValue({
        name: this.selectedDetails?.name,
        shortName: this.selectedDetails?.shortName,
        address: {
          line1: this.selectedDetails?.address?.line1 || '',
          line2: this.selectedDetails?.address?.line2 || '',
          city: this.selectedDetails?.address?.city || '',
          state: this.selectedDetails?.address?.state || '',
          country: this.selectedDetails?.address?.country || '',
          pincode: this.selectedDetails?.address?.pincode || '',
        }
      });
      this.partyForms.clear()
      if (this.selectedDetails.userDetails.length > 0) {
        this.selectedDetails.userDetails.forEach((user: any) => {
          this.addParty(user);
        })
      }
    }
  }

  addContactData() {
    this.lookupService.addContactList({
      ...this.editFormGroup.value
    })?.subscribe({
      next: (res) => {
        if (res) {
          this.submitedData.emit({data : res.data, isEdit : false});
          this.verifyLoader = false;
          this.onCloseIconClick(true);
        }
      }, error: (err) => {
        this.errorMessage = err.error?.message;
        this.verifyLoader = false;
      }
    })
  }

  editContactData() {
    this.lookupService.editContactList(this.editFormGroup.value, this.contactItem._id)?.subscribe({
      next: (res) => {
        if (res) {
          res.data.userDetails = this.editFormGroup.value.userDetails
          this.submitedData.emit({data : res.data, isEdit : true});
          this.verifyLoader = false;
          this.onCloseIconClick(true);
        }
      }, error: (err) => {
        this.errorMessage = err.error?.message;
        this.verifyLoader = false;
      }
    });
  }

  addParty(user: any) {
    const party = this._fb.group({
      name: [user?.name, Validators.required],
      emailAddress: [user?.emailAddress, [
        Validators.required,
        Validators.pattern(RegExpPatterns.EmailPattern),
      ]],
      designation: [user?.designation],
      permissions: [user?.permissions, Validators.required],
      signatoryType : [user?.signatoryType],
      _id: [user?._id]
    });
    this.partyForms?.push(party);
  }

  deleteParty(i: any) {
    this.partyForms.removeAt(i)
  }

  get partyForms() {
    return this.editFormGroup?.get('userDetails') as FormArray;
  }

  get getAddressControl() {
    return this.editFormGroup.controls['address'] as FormGroup;
  }


  onSubmit() {
    this.errorMessage = '';
    this.partyForms.value.forEach((party: any, index: number)=>{
      if(party.permissions.includes('E-Sign') && !party.signatoryType){
        this.errorMessage = `Signatory Type is required for Individual ${index + 1}`
        return;
      }
    })
    this.verifyLoader = true;
    if (this.isEdit) {
      this.editContactData();
    } else {
      this.addContactData();
    }
  }

  updatePermissions(event: any, index: number) {
    let control: FormControl = this.partyForms.controls[index].get('signatoryType') as FormControl;
    if(event.includes('E-Sign')){
      control.setValidators([Validators.required]);
    }else{
      control.setValue('');
      control.clearValidators();
    }
    control.updateValueAndValidity();
    this.partyForms.controls[index].patchValue({
      permissions : event
    });
  }
}
