import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SdexAdminService {
  constructor(private http: HttpClient) {}

  editUser(id: any, payload: any) {
    let url = environment.API_URL + '/v1/user/' + id;
    return this.http.put(url, payload);
  }

  getUserList(companyId: any) {
    let url = environment.API_URL + '/v1/user/company/' + companyId;
    return this.http.get(url);
  }

  getRootCompanyUsers(params: any) {
    let url = environment.API_URL + '/v1/user';
    return this.http.get(url, { params: params });
  }

  getNotificationData() {
    let url = environment.API_URL + '/v1/notification';
    return this.http.get(url);
  }

  markAllNotificationRead() {
    let url = environment.API_URL + '/v1/notification';
    return this.http.put(url, {});
  }

  editCompanyPrefrenceAddressorLogo(data: any, prefId: any) {
    let url = environment.API_URL + `/v1/company-preference/${prefId}`;
    return this.http.post(url, data);
  }

  assignCompany(payload: any, userId: any) {
    let url = environment.API_URL + '/v1/user/assign/' + userId;
    return this.http.put(url, payload);
  }

  getAssignedUserCompany(userId: any) {
    let url = environment.API_URL + '/v1/company/assign/' + userId;
    return this.http.get(url);
  }

  getUserById(id: any) {
    let url = environment.API_URL + '/v1/user/' + id;
    return this.http.get(url);
  }

  getToCompanyList() {
    let params = new HttpParams();
     let url = environment.API_URL + '/v1/company';
    return this.http.get(url, { params: params });
  }

  getSubcompanyList(companyId: any) {
    let url = environment.API_URL + '/v1/company/list/' + companyId;
    return this.http.get(url);
  }

  contactVisibleTo(companyId: any) {
    let params = new HttpParams();
    params = params.append('company', companyId);
    let url = environment.API_URL + '/v1/user';
    return this.http.get(url, { params: params });
  }

  createContact(payload: any) {
    let url = environment.API_URL + '/v1/company-contacts';
    return this.http.post(url, payload);
  }

  getContactList(companyId: any) {
    let params = new HttpParams();
    params = params.append('company', companyId);
    let url = environment.API_URL + '/v1/contacts';
    return this.http.get(url, { params: params });
  }

  getPendingStatus() {
    let url = environment.API_URL + '/v1/contacts/pending';
    return this.http.get(url);
  }

  getContactAllCount() {
    let url = environment.API_URL + '/v1/contacts/all-contacts-count';
    return this.http.get(url);
  }

  getContactByStatus(status: string) {
    let params = new HttpParams();
    params = params.append('filterStatus', status);
    let url = environment.API_URL + '/v1/contacts/pending';
    return this.http.get(url, { params: params });
  }

  addUser(data: any) {
    let url = environment.API_URL + '/v1/user';
    return this.http.post(url, data);
  }
  EditZoneUser(data: any, id: any) {
    let url = environment.API_URL + '/v1/user/' + id;
    return this.http.put(url, data);
  }
  deleteSubCompanyUser(id: any) {
    let url = environment.API_URL + `/v1/user/sub-company-user/${id}`;
    return this.http.delete(url);
  }

  addCompanyUser(data: any, id: any) {
    let url = environment.API_URL + `/v1/user/sub-company-user`;
    return this.http.post(url, data);
  }

  getCompanyDataById(companyId: any) {
    let url = environment.API_URL + `/v1/company/${companyId}`;
    return this.http.get(url);
  }
  getOrganisation() {
    let url = environment.API_URL + '/v1/company/';
    return this.http.get(url);
  }
  addContact(payload: any) {
    let url = environment.API_URL + '/v1/contacts';
    return this.http.post(url, payload);
  }

  getCompanyPreferenceByCompanyId(companyId: any) {
    let url = environment.API_URL + '/v1/company-preference/' + companyId;
    return this.http.get(url);
  }

  getUserListByCompanyId(id: any) {
    let url = environment.API_URL + '/v1/user/sub-company-users';
    return this.http.get(url, { params: { assignedCompany: id } });
  }

  uploadFile(payload: any): Observable<any> {
    return this.http.post(
      environment.API_URL + `/v1/digilocker/public-file/upload`,
      payload
    );
  }

  acceptInvitation(id: any) {
    let url = environment.API_URL + `/v1/contacts/accept/${id}`;
    return this.http.put(url, '');
  }

  rejectInvitation(id: any) {
    let url = environment.API_URL + `/v1/contacts/reject/${id}`;
    return this.http.put(url, '');
  }
  deleteUser(id: any) {
    let url = environment.API_URL + `/v1/user/delete/${id}`;
    return this.http.delete(url);
  }

  checkAssignCompanybyId(id: any) {
    let url = environment.API_URL + `/v1/user/details/${id}`;
    return this.http.get(url);
  }

  selectCompanyList() {
    let url = environment.API_URL + `/v1/contacts`;
    return this.http.get(url, { params: { status: true } });
  }

  getMyCompanyList() {
    let url = environment.API_URL + `/v1/contacts/my-contacts`;
    return this.http.get(url);
  }

  getContactById(id: string) {
    let url = environment.API_URL + `/v1/contacts/my-contacts?contact=` + id;
    return this.http.get(url);
  }

  getCompanyStructure(id: any) {
    let url = environment.API_URL + `/v1/company-structure/structure/${id}`;
    return this.http.get(url);
  }

  getCompanyLevel(id: any) {
    let url = environment.API_URL + `/v1/company/level/${id}`;
    return this.http.get(url);
  }

  esignDetail(payload: any) {
    let url = environment.API_URL + '/v1/esign-detail';
    return this.http.post(url, payload);
  }
  getEsignDetail() {
    let url = environment.API_URL + '/v1/esign-detail/user';
    return this.http.get(url);
  }

  getEsignData(id: any, params: any) {
    let url = environment.API_URL + `/v1/digilocker/document/${id}/esign-data`;
    return this.http.get(url, {params});
  }

  eSignLink(companyId: any, docId: any, payLoad: any) {
    let url =
      environment.API_URL +
      `/v1/verification/${companyId}/${docId}/e-sign-link?envType=${environment.envType}`;
    return this.http.post(url, payLoad);
  }

  createBlockchain(name: string) {
    let url = environment.API_URL + '/v1/blockchain-key/generate';
    return this.http.post(url, { name });
  }

  getPrimaryEsigndata(id: any) {
    let url =
      environment.API_URL + '/v1/user/' + id + '/esign-declaration-details';
    return this.http.get(url);
  }

  getSelfEsigndata(aadharNumber: string) {
    let url = environment.API_URL + `/v1/user/esign-self-declaration-details/${aadharNumber}`;
    return this.http.get(url);
  }

  getPrimaryEsignLink(companyId: any, docId: any, payLoad: any) {
    let url =
      environment.API_URL +
      `/v1/verification/${companyId}/${docId}/declaration`;
    return this.http.post(url, payLoad);
  }

  getSelfEsignLink(companyId: any, docId: any, payLoad: any) {
    let url =
      environment.API_URL +
      `/v1/verification/${companyId}/${docId}/self-declaration?envType=${environment.envType}`;
    return this.http.post(url, payLoad);
  }

  getBlockchainData() {
    let url = environment.API_URL + '/v1/blockchain-key/retrieve';
    return this.http.get(url);
  }

  changePrimary(userId: any, companyId: any) {
    let url =
      environment.API_URL + '/v1/user/primary/' + userId + '/' + companyId;
    return this.http.put(url, '');
  }
  getSubcompanyUsersList(params: any = {}) {
    return this.http.get(
      `${environment.API_URL}/v1/user/all/sub-company-users`,{params : params});
  }

  getSubCompanyUsersCount(params: any = {}){
    return this.http.get(
      `${environment.API_URL}/v1/user/all/sub-company-users-count`,{params : params});
  }
  serachContactListByName(name: any) {
    let params = new HttpParams();
    params = params.append('name', name);
    let url = environment.API_URL + '/v1/contacts/available-company';
    return this.http.get(url, { params: params });
  }

  getActiveContactList(params?: any) {
    return this.http.get(`${environment.API_URL}/v1/contacts/my-contacts`, {
      params,
    });
  }

  getActiveContactListCount(params?: any) {
    return this.http.get(`${environment.API_URL}/v1/contacts/my-contacts-count`, {
      params,
    });
  }

  getAllContactList(companyID: any) {
    return this.http.get(`${environment.API_URL}/v1/contacts/all-contacts?company=${companyID}`);
  }

  getRolesByUserId(userId: any) {
    let url = environment.API_URL + '/v1/user/role-by-user-id/' + userId;
    return this.http.get(url);
  }

  getEsignDataOnOrg(id: any, params: any, company: any) {
    let url = environment.API_URL + `/v1/digilocker/document/${company}/${id}/esign-data`;
    return this.http.get(url, {params});
  }

  getDocumentvaultStatus() {
    let url = environment.API_URL + `/v1/digilocker/document/vault/status`;
    return this.http.get(url);
  }
}
