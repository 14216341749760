import { Component } from '@angular/core';
import { LookupService } from '../share/services/lookup/lookup.service';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faBan, faCircleCheck, faHistory } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationPopupComponent } from 'src/app/share/popups/confirmation-popup/confirmation-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { APPTYPES } from '../share/constants/common-constants';
import { CommonService } from '../share/services/common/common.service';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrl: './lookup.component.scss'
})
export class LookupComponent {
  verifyLoader: boolean = false;
  metadataList: any;
  isLookup: boolean = false;
  isSelectRadio: boolean = false;
  isSelectCheckbox: boolean = false;
  data: any;
  addedFields: any[] = [];
  errorMessage: string;
  tabvalue: any = 'documenttype';
  selfDetails: any = {};
  editItem: any = null;
  fieldType: string = '';
  isSuccessToster: boolean = false;
  uploadErrorMessage: string = '';
  isToasterMessage: boolean =false;
  isdocumenttype: any;
  documentType: any = null;
  isModule: any;
  moduleItem: any;
  companyDoc: any;
  fieldHistoryList: any[] = [];
  label: any;
  iscontracttype: any;
  isrecordtype: any;
  isEnvTypeClms: boolean = false;
  masterData: any;
  isOpenAddMasterDataPanel: boolean = false
  isLoadRecords: boolean = false
  docId: any;
  envType = environment?.envType || 'SDEX';
  appType = APPTYPES;
  message : string =" "


  public readonly faEdit = faEdit;
  protected faBan = faBan;
  protected faCircleCheck = faCircleCheck;
  protected faHistory = faHistory;

  constructor(
    private lookupService: LookupService,
    private storageService: StorageService,
    private modal: NgbModal,
    public commonService    : CommonService,

  ) { }

  ngOnInit(): void {
    this.selfDetails = this.storageService.getDataFromLocalStorage('selfDetails');
    if (this.envType === this.appType.clms) {
      this.onTabselect('recordtype');
      this.isEnvTypeClms = true;
    }
  }

  addModule() {
    this.isModule = new Date();
  }

  addLookup() {
    this.isLookup = true;
  }

  AddDocumentType() {
    this.isdocumenttype = new Date();

  }

  onTabselect(value: any) {
    if (value === this.tabvalue) {
      return;
    }
    this.tabvalue = value;
    this.getMasterData();
  }

  onCloseIconClick(value: any) {
    this.isLookup = false;
    this.isModule = false;
    this.isSelectRadio = false;
    this.isSelectCheckbox = false;
    this.editItem = null;
    this.addedFields = [];
    this.verifyLoader = false;
    this.isdocumenttype = false;
    if (value) {
      this.getMetadataByDocId(this.docId);
    }
  }

  getMetadataList() {
    this.isLoadRecords = true;
    this.lookupService.getMetadatalist(this.selfDetails?.rootCompany)?.subscribe({
      next: (res) => {
        if (res.success) {
          this.isLoadRecords = false;
          this.metadataList = res.data;
        }
      }, error: (err) => {
        this.isLoadRecords = false;
        this.errorMessage = err.error?.error?.message;
      }
    })
  }

  editLookup(item: any) {
    if (item.status === 'Disabled') {
      this.isSuccessToster = true;
      this.uploadErrorMessage = 'Only enabled status can be edited!';
      setTimeout(() => {
        this.isSuccessToster = false;
      }, 3000);
    } else {
      this.editItem = item;
      this.isLookup = true;
    }
  }

  closeToster(value: any) {
    this.isSuccessToster = true;
    this.uploadErrorMessage = '';
  }

  disableMetadata(value: any, status: string) {
    let msg = status === 'Enabled' ? 'Disable' : 'Enable';
    let Status = status === 'Enabled' ? 'Disabled' : 'Enabled';
    const dialogRef = this.modal.open(ConfirmationPopupComponent, {
      keyboard: false,
      backdrop: 'static',
    })
    dialogRef.componentInstance.message = `Are you sure to ${msg} "${value.label}"`;
    dialogRef.componentInstance.header = `${msg} Metadata`;
    dialogRef.componentInstance.lableName = msg;
    dialogRef.result?.then((result) => {

      if (result && value._id) {
        if (status == 'Enabled') {
          value.enabledLoader = true;
        }
        if (status == 'Disable') {
          value.disableLoader = true;
        }
       this.disabledMetadata(value, Status)
       
      }
    });
  }

  disabledMetadata(value: any, Status: any) {
    this.lookupService.disableMetadata(value._id).subscribe({
      next: (res) => {
        if (res.success) {
          this.getMetadataByDocId(this.docId);
          value.disableLoader = false;
          value.enabledLoader = false;
          this.isToasterMessage =true;
          this.uploadErrorMessage =`Metadata ${Status.toLowerCase()} successfully!`;
          setTimeout(() => {
            this.isToasterMessage = false; 
        }, 3000);
        } else {
          this.errorMessage = res.message;
        }
      },
      error: (err) => {
        this.errorMessage = err.error?.error?.message;
        value.disableLoader = false;
        value.enabledLoader = false;
      }
    });
  }

  editModuleItem(data: any) {
    this.isModule = true;
    this.moduleItem = data
  }

  viewDocmentType(value: any) {
    this.docId = value._id;
    this.tabvalue = 'documentmetadata';
    // this.tabvalue ='contracttype'
    this.companyDoc = value;
    this.getMetadataByDocId(value._id)

  }

  getMetadataByDocId(id: any) {
    this.lookupService.getMetadataByDocId(id)?.subscribe({
      next: (res) => {
        if (res.success) {
          this.metadataList = res.data;
        }
      }, error: (err) => {
        this.errorMessage = err.error?.error?.message;
      }
    })
  }

  getFieldHistory(data: any, contant: any) {
    this.label = data.label;
    this.lookupService.getFieldHistory(data?._id).subscribe({
      next: (res) => {
        if (res.success) {
          this.fieldHistoryList = res.data
          const dialogRef = this.modal.open(contant, {
            keyboard: false,
            backdrop: 'static',
            size: 'lg',
            // centered: true,
          })
          if(dialogRef.result.then){
          dialogRef.result.then((result) => {//close
          }, (reason) => {

          });
        }
      }
      }, error: (err) => {
        this.errorMessage = err.error?.error?.message;
      }
    })
  }

  gotoBack() {
    this.tabvalue = 'documenttype';
    this.companyDoc = undefined;
  }

  getMasterData() {
    this.isLoadRecords = true;
    this.lookupService.getMasterList({
      masterType: this.tabvalue === 'contracttype' ? 'contractType' : 'recordType'
    })?.subscribe((res: any) => {
      this.isLoadRecords = false;
      this.masterData = res.data;
    })
  }

  openAddClmsMasterDataType() {
    this.isOpenAddMasterDataPanel = true
  }

  closeAddClmsMasterDataType(isSave: boolean) {
    if (isSave) {
      this.getMasterData();
    }
    this.isOpenAddMasterDataPanel = false
  }

  updateContactTypesData(event: any) {
    this.getMasterData();
  }

  onSubmit(event: any) {
    if (event.success) {
      this.isToasterMessage = true;
      this.uploadErrorMessage = event.message;
      setTimeout(() => {
        this.isToasterMessage = false;
      }, 3000);
    } 
  }

}


