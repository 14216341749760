<section class="editDocument__container">
  <header>
    <app-offcanvas-header paddingClass="paddingClass"
      header="{{ documentType ? 'Edit Category Type' : 'Add Category Type' }}"
      (closeClicked)="onCloseIconClick(false)"></app-offcanvas-header>
  </header>
  <form class="mt-3" [formGroup]="documentTypeFormGroup">
    <div class="offcanvas__content">
      <div class="offcanvas__orgDetails">
        <div class="offCanvas__orgName-inputField">
          <app-input-filed label="Name" placeholder="Enter name" 
            [control]="$any(documentTypeFormGroup).controls['name']" [starIcon]="'*'"
            [value]="documentType?.name"></app-input-filed>
          <small class="text-danger"
            *ngIf="documentTypeFormGroup.get('name')?.invalid && documentTypeFormGroup.get('name')?.touched">
            Name is required.
          </small>
          <small *ngIf="errorMessage" class="text-danger">{{ errorMessage }}
          </small>
          </div>
          </div>
          </div>
          
    <div class="offcanvas__content" *ngIf="tabvalue == 'recordtype'">
      <div class="offcanvas__orgDetails">
        <div class="offCanvas__orgName-inputField">
          <app-input-filed label="Record Type" placeholder="Enter recordtype" class="mt-3"
            [control]="$any(documentTypeFormGroup).controls['recordtype']" [starIcon]="'*'"
            [value]="documentType?.name"></app-input-filed>
          <small class="text-danger"
            *ngIf="documentTypeFormGroup.get('recordtype')?.invalid && documentTypeFormGroup.get('recordtype')?.touched">
            Record Type is required.
          </small>
          <small *ngIf="errorMessage" class="text-danger">{{ errorMessage }}>
          </small>
        </div>
      </div>
    </div>
         
    <div class="offcanvas__content" *ngIf="tabvalue == 'contracttype'">
      <div class="offcanvas__orgDetails">
        <div class="offCanvas__orgName-inputField">
          <app-input-filed label="Contract Type" placeholder="Enter contracttype"
            [control]="$any(documentTypeFormGroup).controls['contracttype']" [starIcon]="'*'"
            [value]="documentType?.name"></app-input-filed>
          <small class="text-danger"
            *ngIf="documentTypeFormGroup.get('contracttype')?.invalid && documentTypeFormGroup.get('contracttype')?.touched">
            Contract Type is required.
          </small>
          <small *ngIf="errorMessage" class="text-danger">{{ errorMessage }}>
          </small>
        </div>
      </div>
    </div>

 <div class="offcanvas__content" *ngIf="tabvalue === 'contracttype' || tabvalue === 'recordtype'">
  <div class="offcanvas__orgDetails">
    <div class="offCanvas__orgName-inputField">
      <app-input-filed *ngIf="tabvalue === 'contracttype' || tabvalue === 'recordtype'" label="Description"
        placeholder="Enter description" [control]="$any(documentTypeFormGroup).controls['description']" 
        [value]="documentType?.name">
      </app-input-filed>
    </div>
  </div>
</div>


<div class="form-group" *ngIf="!showDropDown">
  <label class="filterOffcanvas__inputlabel">Select MetaData</label>
  <div class="form-control form-select filterOffcanvas__inputlabel">
    <div (mouseleave)="showDropDown = false">
      <button class="drop-toggle btn flat" (click)="showDropdown()">
        <span class="spancolor" *ngIf="checkedMultiList.length<=0">
          Select MetaData</span>
        <span class="spancolor" *ngIf="checkedMultiList.length>0">{{checkedMultiList.join(', ')}}</span>
      </button>
      <div class="drop-show" *ngIf="showDropDown && listData.length>0">
        <div class="usersFilter__branchesField">
          <div class="usersFilter__branchWrappar">
            <img class="usersFilter__searchIcon" src="../../../../assets/Images/user-management/searchIcon.svg"
              alt="search" />
            <input (input)="handleFilter($event)" class="usersFilter__input" placeholder="Search" />
          </div>
        </div>
        <label *ngFor="let item of listData">
          <app-checkbox checkboxStatus="{{item.checked}}"
            (checked)="getMultiSelectedValue($event.event.target.checked, item)"></app-checkbox>
          <span>{{item.label}}</span>
        </label>
      </div>
    </div>
  </div>
</div>

  </form>
  <div class="editDocument__footer">
    <div class="offCanvas__footer">
      <app-common-button buttonClass="smallbutton" buttonLabel="Cancel"
        (clickEvent)="onCloseIconClick(false)"></app-common-button>
      <app-common-button *ngIf="commonService.orgLock()" buttonClass="smallFilledButton" buttonLabel="Submit" (clickEvent)="onSubmit()"
        [loadingIcon]="verifyLoader" [disabled]="documentTypeFormGroup.invalid"></app-common-button>
    </div>
  </div>
</section>